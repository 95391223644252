import React, {Component} from 'react';
import {NavLink, BrowserRouter} from "react-router-dom";
import AnimatedRoutes from "./AnimatedRoutes";

class App extends Component {
    render() {
        return (
            <React.Fragment>
                <BrowserRouter>
                    <header className="main">
                        <h1>
                            <NavLink to="/portfolio">Vadim's Web Development</NavLink>
                        </h1>
                        <nav>
                            <NavLink to="/portfolio">portfolio</NavLink>
                            {/*<NavLink to="/resume">résumé</NavLink>*/}
                            <NavLink to="/contact">contact</NavLink>
                        </nav>
                    </header>

                    <AnimatedRoutes/>

                </BrowserRouter>
            </React.Fragment>
        );
    }
}

export default App;
