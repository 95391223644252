import React, {Component} from 'react';
import ContactPoint from './ContactPoint';
import helpers from './helpers';
import {motion} from 'framer-motion';

class ContactInfo extends Component {
  render() {
    return (
      <motion.div
        className="contact-info"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={helpers.pageVariants}
      >
        <ul className="contact-points">
          <ContactPoint name="Phone" info="+1 (647) 398-0517"/>
          <ContactPoint name="WhatsApp" info="+1 (647) 398-0517"/>
          <ContactPoint name="Telegram" info="@vadimstelegram"/>
          <ContactPoint name="Email" info="vadim@haiduk.org"/>
          <ContactPoint name="LinkedIn" info="vadim-h"/>
        </ul>
        <div className="qr-code--v-card">
          {/*<a href="vadims-contact-info.vcf" download className="btn">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"/></svg>
              save<span className='extra-wording'>&nbsp;to contacts</span>
            </span>
          </a>*/}
          <div className="scan">
            <img src="img/contact-qr-code.png" alt=""/>
            save to contacts
          </div>
        </div>
      </motion.div>
    );
  }
}

export default ContactInfo;
