import React from 'react';
import {Navigate, Route, Routes, useLocation, useParams} from "react-router-dom";
import Portfolio from "./Portfolio";
import Resume from "./Resume";
import ContactInfo from "./ContactInfo";
import NotFound from "./NotFound";
import {AnimatePresence} from 'framer-motion';
import PortfolioItem from "./PortfolioItem";

function AnimatedRoutes() {
  const PortfolioItemWrapper = () => {
    const params = useParams();
    return <PortfolioItem portfolioItem={params.portfolioItem}/>
  }
  const location = useLocation();
  return (
      <AnimatePresence exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          <Route index element={<Navigate to="/portfolio" replace={true}/>}/>
          <Route path="/" element={<Portfolio/>}/>
          <Route path="/portfolio" element={<Portfolio/>}/>
          <Route path="/resume" element={<Resume/>}/>
          <Route path="/contact" element={<ContactInfo/>}/>
          <Route path="*" element={<NotFound/>}/>
          <Route path="/portfolio/:portfolioItem" element={<PortfolioItemWrapper/>}/>
        </Routes>
      </AnimatePresence>
  )
}

export default AnimatedRoutes;
