import React, {Component} from 'react';
import SecureEmailAddress from "./SecureEmailAddress";

class ContactPoint extends Component {
  render() {
    let url, icon;
    const type = this.props.name.toLowerCase();
    if (type === 'phone') {
      url = 'tel:' + this.props.info.replace(/[^\d+]/g, '');
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792">
          <path className="st0" d="M1600,1240c0,18-3.3,41.5-10,70.5s-13.7,51.8-21,68.5c-14,33.3-54.7,68.7-122,106c-62.7,34-124.7,51-186,51
            c-18,0-35.7-1.2-53-3.5s-36.5-6.5-57.5-12.5s-36.7-10.8-47-14.5s-28.8-10.5-55.5-20.5c-26.7-10-43-16-49-18
            c-65.3-23.3-123.7-51-175-83c-84.7-52.7-172.7-124.7-264-216S396.7,988.7,344,904c-32-51.3-59.7-109.7-83-175c-2-6-8-22.3-18-49
            s-16.8-45.2-20.5-55.5s-8.5-26-14.5-47s-10.2-40.2-12.5-57.5c-2.3-17.3-3.5-35-3.5-53c0-61.3,17-123.3,51-186
            c37.3-67.3,72.7-108,106-122c16.7-7.3,39.5-14.3,68.5-21s52.5-10,70.5-10c9.3,0,16.3,1,21,3c12,4,29.7,29.3,53,76
            c7.3,12.7,17.3,30.7,30,54s24.3,44.5,35,63.5s21,36.8,31,53.5c2,2.7,7.8,11,17.5,25s16.8,25.8,21.5,35.5s7,19.2,7,28.5
            c0,13.3-9.5,30-28.5,50s-39.7,38.3-62,55s-43,34.3-62,53S523,659,523,671c0,6,1.7,13.5,5,22.5s6.2,15.8,8.5,20.5s7,12.7,14,24
            s10.8,17.7,11.5,19c50.7,91.3,108.7,169.7,174,235s143.7,123.3,235,174c1.3,0.7,7.7,4.5,19,11.5s19.3,11.7,24,14s11.5,5.2,20.5,8.5
            s16.5,5,22.5,5c12,0,27.3-9.5,46-28.5s36.3-39.7,53-62s35-43,55-62s36.7-28.5,50-28.5c9.3,0,18.8,2.3,28.5,7s21.5,11.8,35.5,21.5
            s22.3,15.5,25,17.5c16.7,10,34.5,20.3,53.5,31s40.2,22.3,63.5,35s41.3,22.7,54,30c46.7,23.3,72,41,76,53
            C1599,1223.7,1600,1230.7,1600,1240z"/>
        </svg>
      );
    } else if (type === 'email') {
      url = 'mailto:' + this.props.info;
      icon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
        <path
          d="M41.3,243.3c26.6,14.3,394.6,212,408.3,219.4c13.7,7.4,31.5,10.8,49.3,10.8c17.9,0,35.6-3.5,49.3-10.8C562,455.4,930,257.6,956.6,243.3c26.6-14.3,51.8-70,2.9-70H38.4C-10.5,173.3,14.7,229,41.3,243.3z M968.9,363.3C938.7,379,566.7,572.8,548.2,582.5c-18.5,9.7-31.5,10.8-49.3,10.8c-17.9,0-30.8-1.1-49.3-10.8C431.1,572.8,61.2,379,31,363.2c-21.2-11.1-21,1.9-21,11.9c0,10,0,397.1,0,397.1c0,22.9,30.8,54.4,54.4,54.4h871.1c23.6,0,54.4-31.6,54.4-54.4c0,0,0-387,0-397C990,365.2,990.2,352.2,968.9,363.3L968.9,363.3z"/>
      </svg>
    } else if (type === 'telegram') {
      url = 'https://telegram.me/' + this.props.info.replace("@", '');
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path className="st0" d="M23.6,2.2C23.4,2,23.1,1.9,22.8,2c-4.2,1.6-17,6.4-22.2,8.3c-0.3,0.1-0.5,0.4-0.5,0.8c0,0.4,0.2,0.7,0.6,0.8
            c2.4,0.7,5.5,1.7,5.5,1.7s1.5,4.4,2.2,6.6c0.1,0.3,0.3,0.5,0.6,0.6c0.3,0.1,0.6,0,0.8-0.2c1.2-1.1,3.1-2.9,3.1-2.9
            s3.6,2.6,5.6,4.1c0.3,0.2,0.7,0.3,1.1,0.1c0.4-0.1,0.6-0.4,0.7-0.8c0.9-4.1,3-14.3,3.8-18C23.9,2.8,23.8,2.5,23.6,2.2z M19.5,5.9
            C15.9,9.2,9.4,15,9.4,15L9,18.5l-1.7-5.5c0,0,7.5-4.9,11.8-7.5c0.1-0.1,0.3-0.1,0.4,0c0,0,0,0,0,0.1C19.6,5.7,19.6,5.8,19.5,5.9
            C19.6,5.9,19.6,5.9,19.5,5.9z"/>
        </svg>
      )
    } else if (type === 'whatsapp') {
      url = 'https://wa.me/' + this.props.info.replace(/\D/g, '');
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000">
          <g transform="translate(0.000000,462.000000) scale(0.100000,-0.100000)">
            <path
              d="M4472.9,4492.4C2377.1,4206.7,746.1,2682.3,347.4,637.7c-198.3-1025.5-51.2-2070.2,428.5-3036l172.7-343.2L520.1-4005.9c-236.7-692.9-424.3-1266.4-420-1270.7c4.3-4.3,601.2,179.1,1324,409.4l1317.6,420l251.6-119.4c1029.8-481.8,2172.6-599.1,3259.9-330.5c1669.4,413.6,3012.6,1709.9,3475.3,3355.8c194,695,226,1522.3,83.2,2228c-328.3,1618.2-1483.9,2963.6-3048.8,3545.6c-236.7,89.6-622.6,189.8-914.7,240.9C5568.8,4520.1,4760.7,4532.9,4472.9,4492.4z M5666.9,3722.8c1571.3-213.2,2906-1383.7,3326-2918.8c179.1-654.6,187.6-1385.8,23.4-2034c-336.9-1326.1-1341-2402.8-2645.9-2835.6c-1115.1-371-2351.7-243-3343.1,347.5c-91.7,53.3-174.8,98.1-183.4,98.1c-6.4,0-356.1-108.8-776.1-243.1c-417.9-132.2-761.1-234.5-761.1-223.9c0,8.5,110.9,343.3,247.3,742l247.3,724.9l-110.9,166.3c-138.6,208.9-362.5,663.1-445.6,904C594.7,334.9,1453.9,2420.1,3257.6,3332.6C4001.7,3707.8,4809.8,3837.9,5666.9,3722.8z"/>
            <path
              d="M3272.6,1878.5c-168.4-83.1-400.8-407.2-486.1-678c-17.1-53.3-38.4-194-44.8-311.3c-14.9-272.9,38.4-509.6,185.5-816.6c108.7-226,360.3-605.5,609.8-916.8c437.1-547.9,976.5-995.7,1488.2-1240.9c398.7-189.8,1012.7-392.3,1272.8-420c356.1-36.3,897.6,200.4,1087.4,477.6c123.7,179.1,191.9,618.3,108.7,695c-42.6,38.4-850.7,432.8-1010.6,494.6c-55.4,21.3-123.7,32-151.4,23.4c-32-6.4-115.1-91.7-209-208.9C5892.9-1313.1,5779.9-1424,5718-1424c-83.1,0-496.8,200.4-729.1,353.9c-296.4,196.2-678,579.9-884.8,889.1c-85.3,125.8-153.5,253.7-153.5,281.4c0,29.9,55.4,113,149.2,221.7c136.4,159.9,255.8,349.6,255.8,409.4c0,12.8-98.1,260.1-217.5,550.1c-140.7,336.9-238.8,545.8-275,584.2c-55.4,55.4-66.1,57.6-279.3,57.6C3402.6,1923.3,3347.2,1914.8,3272.6,1878.5z"/>
          </g>
        </svg>
      )
    } else if (type === 'linkedin') {
      url = 'https://linkedin.com/in/' + this.props.info;
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792">
          <path d="M477,625v991H147V625H477z M498,319c0.7,48.7-16.2,89.3-50.5,122S368,490,312,490h-2c-54.7,0-98.7-16.3-132-49
          s-50-73.3-50-122c0-49.3,17.2-90.2,51.5-122.5S258.7,148,314,148s99.7,16.2,133,48.5S497.3,269.7,498,319z M1664,1048v568h-329v-530
          c0-70-13.5-124.8-40.5-164.5S1225.3,862,1168,862c-42,0-77.2,11.5-105.5,34.5S1013,948,999,982c-7.3,20-11,47-11,81v553H659
          c1.3-266,2-481.7,2-647s-0.3-264-1-296l-1-48h329v144h-2c13.3-21.3,27-40,41-56s32.8-33.3,56.5-52s52.7-33.2,87-43.5
          S1243,602,1285,602c114,0,205.7,37.8,275,113.5S1664,902,1664,1048z"/>
        </svg>
      )
    }

    if (this.props.name === 'Email') {
      return (
        <li className="contact-point">
          <SecureEmailAddress url={url} className="btn" icon={icon} address={this.props.info}/>
        </li>
      );
    } else {
      return (
        <li className="contact-point">
          <a href={url} className="btn" target="_blank" rel="noreferrer">
            <span>
              {icon}
              {this.props.info}
            </span>
          </a>
        </li>
      );
    }
  }
}

export default ContactPoint;
