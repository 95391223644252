import React, {Component} from 'react';

class SecureEmailAddress extends Component {
  state = {
    shown: false
  }

  render() {
    return (
      <a href={this.state.shown ? this.props.url : '#'}
         className={this.props.className}
         onClick={(e) => {
           if (!this.state.shown) {
             e.preventDefault();
             this.setState({shown: true})
           }
         }}
      >
        <span>
          {this.props.icon}
          {this.state.shown ? this.props.address : 'show email'}
        </span>
      </a>
    );
  }
}

export default SecureEmailAddress;
