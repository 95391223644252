import React, {Component} from 'react';
import {Link} from "react-router-dom";
import helpers from './helpers';

class PortfolioItemThumb extends Component {
    render() {
        const sanitized_name = helpers.slugify(this.props.name);
        return (
            <figure tabIndex='0'>
                <Link to={"/portfolio/" + sanitized_name}>
                    <img src={'img/portfolio/logos/' + sanitized_name + '.png'}
                         alt={this.props.name}
                         sizes='(max-width: 400px) 150px, 250px'
                    />
                </Link>
            </figure>
        );
    }
}

export default PortfolioItemThumb;
