const helpers = {
  slugify: function (what) {
    return what.toLowerCase().replace(/ /g, '-').replace(/\./g, '-').replace(/[^a-z0-9-]/g, '');
  },
  pageVariants: {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0, transition: {duration: .25}}
  }
}

export default helpers;
