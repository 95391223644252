const PortfolioData = {
    'fantaisie-florist': {
        name: 'Fantaisie Florist',
        stage: 'beta',
        desc: "Local flower delivery & pickup",
        url: "https://fantaisieflorist.com",
        features: [
            "Unique online shopping experience",
            "PayPal & card payment processing",
            "Coupons creation for customer happiness",
            "Gift cards purchasing and usage on-site"
        ],
        tech: [
            "WordPress",
            "WooCommerce",
            "Bootstrap",
            "jQuery",
            "ES6",
            "SCSS",
            "Grunt",
            "Git"
        ],
        numOfScreenshotSizes: 4,
        screenshots: [
            {
                name: 'Home page',
                url: 'https://fantaisieflorist.com'
            },
            {
                name: 'Shop page',
                url: 'https://fantaisieflorist.com/shop'
            },
            {
                name: 'Product view',
                url: 'https://fantaisieflorist.com/shop/products/jewelry-box'
            },
            {
                name: 'Delivery details view',
                url: 'https://fantaisieflorist.com/shop'
            },
            {
                name: 'Checkout view',
                url: 'https://fantaisieflorist.com/shop'
            },
            {
                name: 'Collection view',
                url: 'https://fantaisieflorist.com/collections'
            },
            {
                name: 'Contact page',
                url: 'https://fantaisieflorist.com/contact'
            }
        ]
    },
    'plaka-restaurant': {
        name: 'Plaka Restaurant',
        stage: 'beta',
        desc: "Local Greek restaurant",
        url: "https://plakarestaurant.ca",
        features: [
            "Restaurant info, catering, reviews, location, and contacts",
            "Interactive food & drink menu"
        ],
        tech: [
            "WordPress",
            "jQuery",
            "ES6",
            "SCSS",
            "Git"
        ],
        numOfScreenshotSizes: 4,
        screenshots: [
            {
                name: 'Home page',
                url: 'https://plakarestaurant.ca'
            },
            {
                name: 'Menu page',
                url: 'https://plakarestaurant.ca/menu'
            },
            {
                name: 'Catering page',
                url: 'https://plakarestaurant.ca/catering'
            },
            {
                name: 'Location page',
                url: 'https://plakarestaurant.ca/location'
            },
            {
                name: 'Contact page',
                url: 'https://plakarestaurant.ca/contact'
            },
        ]
    },
    'the-wavy-line-clinic': {
        name: 'The Wavy Line Clinic',
        desc: "Bioresonance clinic",
        url: "https://thewavylineclinic.co.uk",
        features: [
            "Clinic information, therapy options, and contacts",
            "Resources on bioresonance and what it is",
            "Bio page for the practitioner"
        ],
        tech: [
            "WordPress",
            "SCSS",
            "Git"
        ],
        numOfScreenshotSizes: 4,
        screenshots: [
            {
                name: 'Home page',
                url: 'https://plakarestaurant.ca'
            },
            {
                name: 'What is bioresonance page',
                url: 'https://plakarestaurant.ca/menu'
            },
            {
                name: 'Resources page',
                url: 'https://plakarestaurant.ca/catering'
            },
            {
                name: 'About the practitioner page',
                url: 'https://plakarestaurant.ca/location'
            },
            {
                name: 'Therapy options page',
                url: 'https://plakarestaurant.ca/contact'
            },
            {
                name: 'Contact page',
                url: 'https://plakarestaurant.ca/contact'
            },
        ]
    },
    'straight-in-publications': {
        name: 'Straight In Publications',
        desc: "Comics print publisher",
        url: "https://straightinpublications.com",
        features: [
            "Online shopping experience - individual products & subscriptions",
            "Company info, publications, events, sponsors, vendors, news",
            "Products rating & review writing",
            "PayPal payment processing",
            "Delivery options & rates based on product size/weight"
        ],
        tech: [
            "WordPress",
            "WooCommerce",
            "jQuery",
            "SCSS",
            "Grunt",
            "Git"
        ],
        numOfScreenshotSizes: 4,
        screenshots: [
            {
                name: 'Home page',
                url: "https://straightinpublications.com"
            },
            {
                name: 'Publication page',
                url: "https://straightinpublications.com/publications/agent-undisclothed/"
            },
            {
                name: "News page",
                url: "https://straightinpublications.com/news"
            },
            {
                name: 'Shop page',
                url: "https://straightinpublications.com/shop"
            },
            {
                name: 'Product page',
                url: 'https://www.straightinpublications.com/product/straight-in-issue-1/'
            },
            {
                name: 'Product review form',
                url: 'https://www.straightinpublications.com/product/straight-in-issue-2/'
            },
            {
                name: 'Checkout page',
                url: "https://straightinpublications.com/shop"
            },
            {
                name: 'Sponsors page',
                url: "https://straightinpublications.com/sponsors"
            },
            {
                name: 'Vendors page',
                url: "https://straightinpublications.com/vendors"
            },
            {
                name: 'Investing page',
                url: "https://straightinpublications.com/investing"
            },
            {
                name: 'Contact page',
                url: "https://straightinpublications.com/contact"
            }
        ]
    },
    'receive-cash': {
        name: 'Receive.cash',
        stage: 'beta',
        desc: "All your cryptos on one page",
        url: "https://receive.cash",
        features: [
            "User gets a page with a custom URL to contain wallet addresses / QR codes of various cryptocurrencies for seamless display & sharing",
            "Authentication via email/password or third-party identity providers such as Google, Facebook, Twitter, etc.",
            "Multiple pages per account",
            "Live page updates for a page visitor if its admin changes any of the cryptos",
            "Email verifications & notifications",
        ],
        tech: [
            "React",
            "Firebase",
            "Bootstrap",
            "ES6+, JSX",
            "SCSS",
            "Webpack",
            "Git"
        ],
        numOfScreenshotSizes: 4,
        screenshots: [
            {
                name: 'Home page',
                url: 'https://receive.cash/'
            },
            {
                name: 'Cryptos page',
                url: 'https://receive.cash/vadim'
            },
            {
                name: 'Crypto view',
                url: 'https://receive.cash/vadim/xmr'
            },
            {
                name: 'User pages',
                url: 'https://receive.cash/'
            },
        ]
    },
    'rent-a-car': {
        name: 'Rent a Car',
        desc: "Car & van rentals",
        url: "https://rentacar.haiduk.org",
        features: [
            "Online booking experience",
            "Company info, services, perks, and rates",
            "Booking confirmation emails to customers & site admins",
            "Third-party car rental plugin customized to fit particular needs & preferences",
            "PayPal and Stripe payment processing",
        ],
        tech: [
            "WordPress",
            "Bootstrap",
            "jQuery",
            "SCSS",
            "Grunt",
            "Git"
        ],
        numOfScreenshotSizes: 4,
        screenshots: [
            {
                name: 'Home page',
                url: "https://rentacar.haiduk.org"
            },
            {
                name: 'Car selection view',
                url: "https://rentacar.haiduk.org"
            },
            {
                name: 'Driver details view',
                url: "https://rentacar.haiduk.org"
            },
            {
                name: 'Rental confirmation view',
                url: "https://rentacar.haiduk.org"
            },
            {
                name: 'Location page',
                url: "https://rentacar.haiduk.org/location-hours"
            },
            {
                name: 'Contact page',
                url: "https://rentacar.haiduk.org/contact"
            }
        ]
    },
    'jrj-contracting': {
        name: 'JRJ Contracting',
        desc: "General contractor",
        url: "https://jrjcontracting.com",
        features: [
            "Company & services description",
            "Listing of offered services with photos",
            "Project & contact forms submission",
            "Contact information"
        ],
        tech: [
            "WordPress",
            "SCSS",
            "Git"
        ],
        numOfScreenshotSizes: 4,
        screenshots: [
            {
                name: 'Home page',
                url: "https://jrjcontracting.com"
            },
            {
                name: 'Services page',
                url: "https://jrjcontracting.com/construction"
            },
            {
                name: 'Contact page',
                url: "https://jrjcontracting.com/contact"
            }
        ]
    },
    'westhouse-universal': {
        name: 'Westhouse Universal',
        desc: "International wholesaler of goods",
        url: "https://westhouseuniversal.com",
        features: [
            "Company & services description",
            "Listing of products with photos",
            "Detailed contact info"
        ],
        tech: [
            "WordPress",
            "jQuery",
            "SCSS",
            "Grunt",
            "Git"
        ],
        numOfScreenshotSizes: 4,
        screenshots: [
            {
                name: 'Home page',
                url: "https://westhouseuniversal.com"
            }
        ]
    },
    /*'have-a-dream': {
        name: 'Have a Dream',
        desc: "Foundation",
        url: "https://jrjcontracting.com",
        features: [
            "Company & services description",
            "Listing of offered services with photos",
            "Project & contact forms submission",
            "Contact information"
        ],
        tech: [
            "WordPress",
            "SCSS",
            "Git"
        ],
        numOfScreenshotSizes: 4,
        screenshots: [
            {
                name: 'Home page',
                url: "https://jrjcontracting.com"
            },
            {
                name: 'Services page',
                url: "https://jrjcontracting.com/construction"
            },
            {
                name: 'Contact page',
                url: "https://jrjcontracting.com/contact"
            }
        ]
    }*/
}

export default PortfolioData;
