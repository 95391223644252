import React from 'react';
import {motion} from "framer-motion";
import helpers from './helpers';

export const Resume = () => (
    <motion.div
        className="resume"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={helpers.pageVariants}
    >
        <h2>
            Summary
            {/*<a href="resume.pdf" download className="btn"><span>download</span></a>*/}
        </h2>
        <p>
            Full Stack Web Developer with extensive experience designing and developing commercial and personal
            websites.
            Skilled in both front-end and back-end development, utilizing modern technologies to create scalable,
            responsive applications.
            Proven ability to lead projects, adhere to best practices, and enhance client satisfaction through
            innovative and reliable solutions.
            Actively pursuing opportunities to contribute technical expertise to drive company growth and technical
            advancement.
        </p>

        <h2>Skills</h2>
        <h3>Front-end</h3>
        HTML5 · CSS3/SCSS · JavaScript (ES6+) · React · jQuery · Bootstrap · WCAG
        <h3>Back-end</h3>
        PHP · MySQL · Apache · REST APIs · WordPress · Joomla
        <h3>Workflow & Tools</h3>
        Git · Webpack · Grunt
        <h3>IDE & OS</h3>
        PhpStorm · Linux · Windows · Mac
        <h3>Graphics & Design</h3>
        Photoshop · Illustrator · GIMP

        <h2>Professional Experience</h2>
        <h3>Freelance Full Stack Web Developer</h3>
        <p>2018 - Present</p>
        <ul>
            <li>
                <strong>Technical Consultation</strong>: Advised clients on viable, cost-efficient technical
                requirements, leading to informed decisions on project specifications.
            </li>
            <li>
                <strong>Full Stack Development</strong>: Created custom themes and plugins, integrated complex back-end
                systems, and ensured seamless front-end and back-end functionality for diverse client projects.
            </li>
            <li>
                <strong>CMS Optimization</strong>: Customized WordPress and Joomla platforms, optimizing plugins for
                performance, security, and user experience.
            </li>
            <li>
                <strong>Quality Assurance</strong>: Delivered high-quality, bug-free code by performing rigorous
                cross-platform testing across browsers and devices.
            </li>
            <li>
                <strong>Continuous Improvement</strong>: Maintained up-to-date CMS, security patches, and website
                content to align with client needs as well as the latest industry standards and security protocols.
            </li>
        </ul>

        <h3>Web Developer Intern</h3>
        <p>
            Ogritech Web Development
            <br/>
            2017 - 2018
        </p>
        <ul>
            <li>
                <strong>Front-end Development</strong>:
                Converted mockups into responsive front-end solutions compatible with outdated and modern browsers,
                improving accessibility and UX.
            </li>
            <li>
                <strong>Feature Implementation</strong>:
                Suggested and implemented new website features, enhancing user engagement and functionality aligned with
                company goals.
            </li>
            <li>
                <strong>Project Highlights</strong>:
                Re-engineered a third-party karaoke plugin to create a guitar-centered web app, extending functionality
                to meet a unique client requirement.
            </li>
            <li>
                <strong>Back-end Optimization</strong>:
                Enhanced the back-end admin interface, reducing input time for song data and streamlining content
                management.
            </li>
        </ul>

        <h2>Education</h2>
        <h3>Advanced JavaScript, React, and Full Stack Courses</h3>
        <ul>
            <li>
                <strong>Wes Bos Courses (2021, 2022)</strong>:
                <em>ES6 for Everyone, React For Beginners</em>
            </li>
            <li>
                <strong>React Academy (2020)</strong>:
                <em>3-day React.js Bootcamp – </em>
                Covered key concepts including JSX, component lifecycle, hooks, and state management.
            </li>
            <li>
                <strong>LinkedIn Learning (2018 - 2019)</strong>:
                Completed comprehensive courses in SEO, UX, WordPress Security, and JavaScript development.
            </li>
        </ul>

        <h3>Additional Training</h3>
        <ul>
            <li>
                <strong>Lynda.com (2016)</strong>:
                Advanced PHP Debugging, Test-Driven Development, and Quality Management Principles
            </li>
        </ul>
    </motion.div>
)

export default Resume;
