import React, {Component} from 'react';
import PortfolioData from "./PortfolioData";
import {Link} from "react-router-dom";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import helpers from './helpers';
import {motion} from 'framer-motion';

class PortfolioItem extends Component {
    constructor() {
        super();
        this.state = {
            WindowSize: window.innerWidth
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.addEventListener("resize", null);
    }

    handleResize() {
        this.setState({WindowSize: window.innerWidth})
    }

    render() {
        const slug = this.props.portfolioItem;
        const {name, desc, url, features, tech, screenshots} = PortfolioData[slug];
        let PortfolioDataArr = [];
        let currentPortfolioItemIndex;
        let counter = 0;
        for (let prop in PortfolioData) {
            if (Object.prototype.hasOwnProperty.call(PortfolioData, prop)) {
                PortfolioDataArr.push(PortfolioData[prop]);
                if (PortfolioData[prop].name === name) {
                    currentPortfolioItemIndex = counter;
                }
                counter++;
            }
        }
        let prevPortfolioItem, nextPortfolioItem;

        if (currentPortfolioItemIndex === 0) {
            prevPortfolioItem = PortfolioDataArr[PortfolioDataArr.length - 1];
            nextPortfolioItem = PortfolioDataArr[1];
        } else if (currentPortfolioItemIndex === PortfolioDataArr.length - 1) {
            nextPortfolioItem = PortfolioDataArr[0];
            prevPortfolioItem = PortfolioDataArr[PortfolioDataArr.length - 2];
        } else {
            prevPortfolioItem = PortfolioDataArr[currentPortfolioItemIndex - 1];
            nextPortfolioItem = PortfolioDataArr[currentPortfolioItemIndex + 1];
        }

        return (
            <motion.article
                className="portfolio-item"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={helpers.pageVariants}
            >
                <header>
                    <div className="prev-btn-wrapper">
                        <Link to={`/portfolio/` + helpers.slugify(prevPortfolioItem.name)} className="btn round">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path
                    d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"/></svg>
              </span>
                        </Link>
                    </div>
                    <div className="name--desc--url">
                        <h2>{name}</h2>
                        <div className="desc">{desc}</div>
                        <a href={url} target="_blank" rel="noreferrer"
                           className="url">{url.replace(/http(s)*:\/\//, '')}</a>
                    </div>
                    <div className="next-btn-wrapper">
                        <Link to={`/portfolio/` + helpers.slugify(nextPortfolioItem.name)} className="btn round">
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path
                    d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"/></svg>
              </span>
                        </Link>
                    </div>
                </header>
                <div className="features--tech">
                    <div className="features">
                        {/*<ul className="col">
              {features.map((feature, i) => {
                if (i < Math.ceil(features.length/2)) {
                  return <li key={i}>{feature}</li>
                }
                return false;
              })}
            </ul>
            <ul className="col">
              {features.map((feature, i) => {
                if (i >= Math.ceil(features.length/2)) {
                  return <li key={i}>{feature}</li>
                }
                return false;
              })}
            </ul>*/}
                        <ul>
                            {features.map((feature, i) => {
                                return <li key={i}>{feature}</li>
                            })}
                        </ul>
                    </div>
                    <div className="tech">
                        <div className="col">
                            {tech.map((tech_thing, i) => {
                                return (
                                    <div key={i} className="feature">
                                        <img alt="" src={`/img/icons/` + helpers.slugify(tech_thing) + `.svg`}/>
                                        {tech_thing}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                {screenshots.map((screenshot, i) => {
                    const get_url__size = (dpr) => {
                        const img_file_name = screenshot.name.toLowerCase().replace(/ /g, '-');
                        let img_size_id;
                        let css_pixel_width;

                        if (this.state.WindowSize <= 740) {
                            img_size_id = 1;
                            css_pixel_width = 375;
                        } else if (this.state.WindowSize <= 1080) {
                            img_size_id = 2;
                            css_pixel_width = 700;
                        } else if (this.state.WindowSize <= 1480) {
                            img_size_id = 3;
                            css_pixel_width = 1000;
                        } else {
                            img_size_id = 4;
                            css_pixel_width = 1400
                        }

                        return {
                            src: `/img/portfolio/pages/${slug}/dpr${dpr}/${img_file_name}-${img_size_id}.webp`,
                            scrSetItem: `/img/portfolio/pages/${slug}/dpr${dpr}/${img_file_name}-${img_size_id}.webp ${css_pixel_width * dpr}w`,
                            size: css_pixel_width + 'px'
                        };
                    };

                    return (
                        <figure key={i}>
                            <div className="title-bar">
                                <div className="title">{screenshot.name}</div>
                                <button/>
                                <button/>
                                <button/>
                            </div>
                            <a href={screenshot.url} target="_blank" rel="noreferrer">
                                <LazyLoadImage
                                    alt=""
                                    src={get_url__size(1).src}
                                    srcSet={get_url__size(1).scrSetItem + ', ' + get_url__size(2).scrSetItem}
                                    sizes={get_url__size(1).size}
                                />
                            </a>
                        </figure>
                    )
                })}


            </motion.article>
        );
    }
}

export default PortfolioItem;
