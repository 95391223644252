import React, {Component} from 'react';
import PortfolioItemThumb from './PortfolioItemThumb';
import {motion} from 'framer-motion';
import helpers from './helpers';
import WpLogoCenter from './wp-ani-logo-center.svg';
import WpLogoOutside from './wp-ani-logo-outside.svg';

class Portfolio extends Component {
    render() {
        return (
            <motion.div
                className="portfolio-wrapper"
                initial="initial"
                animate="animate"
                exit="exit"
                variants={helpers.pageVariants}
            >
                <>
                    <ul className='portfolio'>
                        <li><PortfolioItemThumb name="Fantaisie Florist"/></li>
                        <li><PortfolioItemThumb name="Plaka Restaurant"/></li>
                        <li><PortfolioItemThumb name="The Wavy Line Clinic"/></li>
                        <li><PortfolioItemThumb name="Straight In Publications"/></li>
                        <li><PortfolioItemThumb name="Receive Cash"/></li>
                        {/*<li><PortfolioItemThumb name="Have a Dream"/></li>*/}
                        <li><PortfolioItemThumb name="Rent a Car"/></li>
                        <li><PortfolioItemThumb name="JRJ Contracting"/></li>
                        <li><PortfolioItemThumb name="Westhouse Universal"/></li>
                    </ul>
                    {/*<footer>
                        <a href="https://wp20.wordpress.net" target={'_blank'} rel={'noreferrer'}>
                            <img src={WpLogoOutside} className={'wp-ani-logo-outside'} alt={'20 years together!'}/>
                            <img src={WpLogoCenter} className={'wp-ani-logo-center'} alt={'WordPress anniversary'}/>
                        </a>
                    </footer>*/}
                </>
            </motion.div>
        );
    }
}

export default Portfolio;
